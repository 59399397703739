import myAxios from '../utils/request'

// 登录
const ApiLogin = (data = {}) => {
  return myAxios.post('/User/Login', {
    userCode: data.userCode,
    headImgUrl: data.headImgUrl,
    nickName: data.nickName,
    // 云货官方传过来的 PlatForm
    platForm: data.platform,
    // 云货官方注册url传过来的参数
    registerUrl: data.registerUrl
  })
}

export {
  // 同步机器人群信息
  ApiLogin
}
