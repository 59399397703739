<template>
	<div class="page login__page flex column">
		<card class="w100 flex column" background="#F6F5F5">
			<div class="b fs-18">第一步，<span class="color-primary">分享</span>二维码到附近设备</div>
			<img src="./assets/img_guide_1.png" class="mt-4 login_guide__img" alt="">
		</card>
		<card class="w100 flex column mt-4" background="#F6F5F5">
			<div class="b share text-center">
				<p class="fs-18">第二步，用当前手机，<span class="color-primary">面对面</span>扫</p>
				<p class="fs-18"><span class="color-primary">附近</span>设备上的二维码</p>
			</div>
			<img src="./assets/img_guide_2.png" class="mt-4 login_guide__img" alt="">
		</card>
		<button class="button login_button" @click="handleStart">立即登录</button>
		<!--		<div class="start_up__tip" @click="handleTarget">注意事项</div>-->
	</div>
</template>

<script>
import { getParams, Platform } from '../../utils'
import { Notify, Toast } from 'vant'
import { ApiLogin } from '../../api/User'
import { ApiGetPorts } from '../../api/WxRobot'
import Card from '../../components/Card'

export default {
	async beforeRouteEnter(to, form, next) {
		//todo 应该判断有没有端口
		const userInfo = getParams(location.href)
		// 如果带有TestLogin字段就放入session 作为模拟操作凭证
		if (userInfo.type === 'TestLogin') {
			sessionStorage.setItem('yh_H5:isTestLogin', 'true')
		}
		// 说明是首次登录
		if (form.path === '/') {
			const HasToken = !!localStorage.getItem('yh_H5:Token')
			// 缓存数据
			const CacheUserInfo = JSON.parse(localStorage.getItem('yh_H5:UserInfo')) || {}
			const RobotInfo = JSON.parse(localStorage.getItem('yh_H5:robotInfo')) || {}
			// 当前链接中的用户信息
			// 当前链接中code与链接code相同 并且有token 同时已登录机器人 说明是重新进入
			if (CacheUserInfo.userCode === userInfo.userCode && HasToken && RobotInfo.robotWxId) {
				/*		const res = await ApiGetPorts()
				 // 如果离线直接登录
				 const path = res[0].state === 0 ? '/login/login' : '/index'
				 next(path)*/
				next('/index')
			} else {
				next()
			}
		} else {
			next()
		}
	},
	name: 'index',
	components: {
		Card
	},
	data() {
		return {
			// 判断是否初次进入启动页时已经获得了 token
			hasToken: !!localStorage.getItem('yh_H5:Token'),
			userInfo: {},
			getPortLoading: false,
			// 定时器
			timeOuter: '',
			// 定时器开始时间
			startTime: 0
		}
	},
	async created() {
		//userCode 必传 avatarUrl nickName  为用户头像和昵称 需跳转传入
		this.userInfo = getParams(location.href)
		await this.initData()
	},
	beforeUnmount() {
		clearTimeout(this.timeOuter)
		this.timeOuter = ''
	},
	computed: {
		cacheUserCode() {
			return this.$store.getters.userInfo.userCode
		},
		robotInfo() {
			return this.$store.getters.robotInfo
		},
		// 是否还有端口并含有设备id
		hasPort() {
			return !!(this.robotInfo && this.robotInfo.deviceId)
		}
	},
	methods: {
		async initData() {
			// 不是从首页跳转过来(注销重新登录)并且当前链接中的code和缓存中的code不一样 或者没有token时
			if ((this.$route.query.from !== 'index' && this.cacheUserCode !== this.userInfo.userCode) || !this.hasToken) {
				this.clearToken()
				await this.getToken()
			}
			// 非重新登录要Url信息
			if (this.$route.query.from !== 'index') {
				// 实时更新用户信息 以每次跳转过来的url参数为准
				this.$store.commit('resetUserInfo')
				await this.getUserInf()
			}
			if ((this.userInfo.userCode || this.userInfo.userCode !== 'xxx') && this.hasToken) {
				await this.getPort()
			}
		},
		clearToken() {
			this.$store.commit('resetRobotInfo')
			localStorage.removeItem('yh_H5:Token')
		},
		async getUserInf() {
			// 以下nickName 和 userCode登录时必传
			const userInfo = this.userInfo
			try {
				userInfo.nickName = decodeURIComponent(decodeURIComponent(userInfo.nickName))
				userInfo.avatarUrl = userInfo.avatarUrl ? decodeURIComponent(decodeURIComponent(userInfo.avatarUrl)) : ''
			} catch (e) {
				// 防止二次解码失败
				userInfo.nickName = decodeURIComponent(userInfo.nickName)
				userInfo.avatarUrl = userInfo.avatarUrl ? decodeURIComponent(userInfo.avatarUrl) : ''
				console.log(e, 'getUserInf')
			}
			await this.$store.dispatch('setUserInfo', userInfo)

		},
		async getToken() {
			if (!this.userInfo.userCode || this.userInfo.userCode === 'xxx') {
				await this.$router.replace('/login/404')
			}
			const toast = Toast.loading({
				message: '数据加载中...',
				forbidClick: true,
				loadingType: 'spinner',
				duration: 0
			})
			try {
				// https://yh-mallrobot-web.mbxdata.cn/#/login/index?userCode=&avatarUrl=&nickName=&userId=&organizationId=&token=&platform=xcx
				const queryLength = window.location.href.indexOf('?')
				const registerUrl = window.location.href.slice(queryLength + 1)
				const res = await ApiLogin({
					...this.userInfo,
					headImgUrl: this.userInfo.avatarUrl,
					registerUrl: registerUrl
				})
				if (res) {
					this.hasToken = true
					await localStorage.setItem('yh_H5:Token', res.token)
					// await localStorage.setItem('yh_H5:permission', res.type)
				}
				toast.clear()
			} catch (e) {
				toast.clear()
				if (e === -10001) {
					await this.$router.replace('/login/noRobot')
				}
			}
		},
		async getPort() {
			try {
				const res = await ApiGetPorts()
				const robot = res[0]
				if (!robot) {
					this.loadingPort()
					return
				}
				// 关闭加载提示
				Toast.clear()
				Notify({
					type: 'success',
					message: '端口加载成功',
					duration: 1500
				})
				this.$store.dispatch('setRobotInfo', robot)
				this.getPortLoading = false
				if (robot.robotWxId) {
					await this.$router.replace('/index')
				}
			} catch (e) {
				if (e === -10001) {
					await this.$router.replace('/login/noRobot')
				}
				console.log(e)
			}
		},
		loadingPort() {
			// 没有端口就进行重置
			this.$store.commit('resetRobotInfo')
			if (!this.getPortLoading) {
				Toast.loading({
					message: '端口分配中，请耐心等待...',
					forbidClick: true,
					duration: 0
				})
				this.startTime = Date.now()
			}
			// 端口加载中
			this.getPortLoading = true
			// 定时1分钟 判断是否超时
			if (Date.now() - this.startTime > 60000) {
				Toast.clear()
				Notify({
					type: 'warning',
					message: '赠送端口超时，请重新进入该页面',
					duration: 2000
				})
				return
			}
			const _this = this
			this.timeOuter = setTimeout(_this.getPort, 6000)
		},
		handleTarget() {
			this.$router.push('precautions')
		},
		handleStart() {
			if (!this.hasToken) {
				const msg = Platform.IsWX() ? '请于三秒后刷新页面重试！' : '请于三秒后重新进入该页面！'
				Notify({
					type: 'warning',
					message: `当前数据有误，${msg}`,
					duration: 1500
				})
				return
			}
			if (!this.hasPort) {
				Notify({
					type: 'warning',
					message: '赠送端口超时，请重新进入该页面',
					duration: 2000
				})
				return
			}
			this.$router.push('login')
		}
	}
}
</script>

<style lang="scss" scoped>
.login__page {
	background: linear-gradient(136deg, #FF677C 0%, #FF445E 100%);
	padding: 20px 12px;
	padding-bottom: 80px;
	min-height: calc(100vh + 80px);
}


.w100 {
	padding-top: 20px;
	padding-bottom: 20px;
	box-sizing: border-box;
	width: 100%;
}

.login_guide__img {
	width: 280px;
	height: 188px
}

.login_button {
	position: fixed;
	bottom: 20px;
	background: #FE455E;
	width: 170px;
	height: 40px;
	font-size: 18px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	border-radius: 4px;
	border: 1px solid #FFFFFF;
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {

	.login_button {
		bottom: calc(constant(safe-area-inset-bottom) + 20px); /* 兼容 iOS < 11.2 */
		bottom: calc(env(safe-area-inset-bottom) + 20px); /* 兼容 iOS >= 11.2 */
	}
}
</style>
